import React from 'react'
import { GlobalNav } from './GlobalNav'

export const Header: React.FC = () => {
	return (
		<header className="c-header">
      <div className="c-header__body">
        <div className="c-header__title">
          <p><a href="/"><img src="/img/logo_new.png" alt="BETSUDAI" /></a></p>
        </div>
        <div className="c-header__nav">
          <div className="c-header__nav-service">
            <GlobalNav />
          </div>
          <div className="c-header__nav-global">
            <div className="c-header__nav-btn">
              <button><span data-item="menuline" /><span data-item="txt">MENU</span></button>
            </div>
            <div className="c-header__nav-menu">
              <ul>
                <li><a href="/#service">サービス一覧</a></li>
                <li><a href="/event/">イベント情報</a></li>
                <li><a href="/afterservice/#afterservice01">お客さまサポート</a></li>
                <li><a href="/company/">企業情報</a></li>
                <li><a href="/recruit/">採用情報</a></li>
                <li><a href="/contact/">お問い合わせ</a></li>
                {/* <li data-type="sns">
                  <ul>
                    <li><a href="https://www.facebook.com/betsudai" target="_blank"><img src="/img/icon-facebook.png" alt="ベツダイ公式facebook" /></a></li>
                    <li><a href="https://www.instagram.com/betsudai_pr/" target="_blank"><img src="/img/icon-instagram.png" alt="ベツダイ公式Instagram" /></a></li>
                  </ul>
                </li> */}
              </ul>
              <div className="c-header__nav-service-sp">
                <GlobalNav />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
	)
}
