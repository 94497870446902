import React, {FC} from 'react'

export const GlobalNav: React.FC = () => {
  return (
    <div className="c-nav-global">
      <ul data-level="01">
        <li data-type="buy">
          <button className="c-header__nav-servicebtn"><img src="/img/nav-buy.png" alt="家を、売る・買う" /></button>
          <ul className="c-header__nav-servicelist" data-level="02">
            <li data-level="02">
              <p><a href="/buy/#sell">売&ensp;る</a></p>
              <ul data-level="03">
                <li><a href="/buy/#buy01">不動産売却</a></li>
              </ul>
            </li>
            <li>
              <p><a href="/buy/#buy">買&ensp;う</a></p>
              <ul data-level="03">
                <li><a href="/buy/#buy02">新築戸建</a></li>
                <li><a href="/buy/#buy03">土地</a></li>
                <li><a href="/buy/#buy04">新築マンション</a></li>
                <li><a href="/buy/#buy05">中古戸建・中古マンション</a></li>
                <li><a href="/buy/#buy06">収益物件</a></li>
                <li><a href="/buy/#buy07">リゾート物件</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li data-type="rent">
          <button className="c-header__nav-servicebtn"><img src="/img/nav-rent.png" alt="家を、貸す・借りる" /></button>
          <ul className="c-header__nav-servicelist" data-level="02">
            <li>
              <p><a href="/rent/#lend">貸&ensp;す</a></p>
              <ul data-level="03">
                <li><a href="/rent/#rent01">賃貸管理</a></li>
              </ul>
            </li>
            <li>
              <p><a href="/rent/#borrow">借りる</a></p>
              <ul data-level="03">
                <li><a href="/rent/#rent02">賃貸</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li data-type="build">
          <button className="c-header__nav-servicebtn"><img src="/img/nav-build.png" alt="家を、建てる･リフォーム" /></button>
          <ul className="c-header__nav-servicelist" data-level="02">
            <li>
              <p><a href="/build/#build">建てる</a></p>
              <ul data-level="03">
                <li><a href="/build/#build01">新築戸建</a></li>
                <li><a href="/build/#build01">規格・注文</a></li>
                <li><a href="/build/#build02">リノベーション</a></li>
              </ul>
            </li>
            <li>
              <p><a href="/build/#reform">リフォーム</a></p>
              <ul data-level="03">
                <li><a href="/build/#build03">リフォーム</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li data-type="afterservice">
          <button className="c-header__nav-servicebtn"><img src="/img/nav-afterservice.png" alt="家を、守る" /></button>
          <ul className="c-header__nav-servicelist" data-level="02">
            <li>
              <p><a href="/afterservice/#afterservice">守&ensp;る</a></p>
              <ul data-level="03">
                <li><a href="/afterservice/#afterservice01">お客さまサポート</a></li>
                <li><a href="/afterservice/#afterservice02">マンション管理</a></li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}
